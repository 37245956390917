<template>
  <div class="view-header view-header--protocol-sharing" v-if="pageTitle">
    <div class="view-header__header">
      <Pagination />
      <Btn v-if="helperService.userHasPermission('protocole_add,protocole_edit', 'OR') &&
        protocol?.statut?.uid !== 'BROUILLON'" class="action-btn" icon="plus" hollow color="white"
        text="Partager un protocole" :to="{ name: 'protocolModalities', params: { id: $route.params.id } }" />
    </div>
    <div class="view-header__footer">
      <BtnBack :to="{ name: 'protocol', id: $route.params.id }" />
      <h1 class="page-title">Partages du protocole - {{ protocol?.titre }}</h1>
    </div>
  </div>
  <div class="view-body">

    <p class="text-align-center padding" v-if="protocol?.statut?.uid === 'BROUILLON'">Il n'est pas possible de partager un
      protocole en cours d'élaboration.</p>
    <Section v-else class="section section--list">
      <List v-if='list.api.endpoint' :api="list.api" :header="list.headers" :items="list.col" :item-key="list.key"
        :disableCheckbox="true" :disableActions="true">
        <template v-slot:entite="{ item }">
          {{ item.entite?.nom }}
        </template>
        <template v-slot:date_partage="{ item }">
          {{ new Date(item.date_partage).toLocaleString() }}
        </template>
        <template v-slot:partage_accepte="{ item }">
          <template v-if="!item.partage_accepte && !item.partage_refuse">
            en attente
          </template>
          <template v-if="item.partage_accepte && !item.partage_refuse">
            Oui
          </template>
          <template v-if="item.partage_refuse">
            Non
          </template>
        </template>
        <template v-slot:actions="{ item }">
          <Btn icon="trash-alt" default @click="openModal('deleteItems', item)" round grow />
        </template>
      </List>
    </Section>
  </div>

  <Modal title="Supprimer le partage&nbsp;?" :active="modal.deleteItems" :data="modalData"
    @modal-close="modal.deleteItems = false">
    <template v-slot:modal-body="{ data }">
      <p>
        Voulez vous vraiment supprimer
        {{ data.length }} le partage&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.deleteItems = false" />
      <Btn text="Supprimer" @click="deleteItems(data)" icon="trash-alt" color="primary" />
    </template>
  </Modal>

  <NavigationDropdownProtocol />
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Pagination from '@/components/list/Pagination.vue'
import NavigationDropdownProtocol
  from '@/views/componentsViews/navigationDropdown/NavigationDropdownProtocol.vue'
import Section from '@/components/layout/Section.vue'
import BtnBack from '@/components/base/BtnBack.vue'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: 'ProtocolSharedView',

  components: {
    Section,
    NavigationDropdownProtocol,
    Pagination,
    List,
    Btn,
    BtnBack,
    Modal,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    const validationSchema = this.yup.object({
      description: this.yup.string().nullable(),
      categorie_id: this.yup.object().nullable(),
      media_id: this.yup.object().nullable(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(
      validationSchema,
    )

    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleSubmit(values)
    })

    return {
      search: {
        attributs: ['titre'],
        filters: {},
      },

      list: {
        api: {
          endpoint: null,
          params: {
            sort: 'id.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Entité' },
          { label: 'Date' },
          { label: 'Accepté' },
          { label: '', shrink: true },
        ],
        col: ['entite', 'date_partage', 'partage_accepte', 'actions'],
        key: 'id',
      },

      modalData: {},
      modal: {
        deleteItems: false,
      },
      protocol: {},
      validationSchema,
      onSubmit,
      isSubmitting,
      errors,
    }
  },

  mounted() {
    this.getProtocol()
  },

  methods: {
    setMedia(event) {
      this.file = event
    },

    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    getProtocol() {
      this.fetchService.get(`protocole/${this.$route.params.id}`).then((response) => {
        this.protocol = response.data
        this.list.api.endpoint = `protocole/${this.$route.params.id}/enfants`
      })
    },

    handleSubmit(values) {
      this.emitter.emit('open-loader')

      this.fetchService.post('fichier', {
        description: values.description,
        categorie_id: values.categorie_id.key,
        media_id: values.media_id.id,
      }).then((response) => {
        this.fetchService.put(`protocole/${this.$route.params.id}/fichier`, {
          fichiers: [
            {
              id: response.data.id,
            },
          ],
        }).then(() => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le fichier a bien été ajouté.',
          })
        })
      })

      this.modal.addFile = false
      this.emitter.emit('close-loader')
    },

    async deleteItems(itemToDelete) {
      this.emitter.emit('open-loader')

      this.fetchService.delete(`protocole/${itemToDelete.id}`).then(
        () => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le partage a été supprimé.',
          })
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data,
          })
        },
      )

      this.emitter.emit('list-refresh')
      this.emitter.emit('close-loader')
      this.modal.deleteItems = false
    },
  },
}
</script>

<style scoped></style>
